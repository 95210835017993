import React from "react";

import LinkButton from "../LinkButton";

import styles from "./styles.module.less";

const consumerConnect = /bayequityhomeloans\.mymortgage-online\.com/i;

const getApplyUrl = (applyNow, applyNowScript, slug) => {
    const pageLink = `${slug}applynow/`;
    if (!!applyNowScript) {
        return pageLink;
    }
    return consumerConnect.test(applyNow) ? applyNow : pageLink;
};
const getLogInUrl = (logIn, slug) =>
    consumerConnect.test(logIn) ? logIn : `${slug}portal/`;
const getLogInTarget = logIn => (consumerConnect.test(logIn) ? "_blank" : null);

const ApplyNowLogIn = ({
    applyNow = "",
    applyNowScript = "",
    logIn = "",
    slug = "",
}) => {
    if (!applyNow && !applyNowScript && !logIn) return null;

    return (
        <div className={styles.ApplyNowLogIn}>
            <ApplyLink
                applyNow={applyNow}
                applyNowScript={applyNowScript}
                slug={slug}
            />
            <LogInLink logIn={logIn} slug={slug} />
        </div>
    );
};

export default ApplyNowLogIn;

export const ApplyLink = ({ applyNow, applyNowScript }) => {
    if (!applyNow && !applyNowScript) return null;

    return (
        <LinkButton
            label="Apply Now / Log In"
            href={applyNow}
            target="_blank"
        />
    );
};

export const LogInLink = ({ logIn, slug }) => {
    if (!logIn) return null;

    let lUrl = getLogInUrl(logIn, slug);
    let lTarget = getLogInTarget(logIn);

    return <LinkButton label="Log in" href={lUrl} target={lTarget} />;
};
