/**
 * UploadCare URLs identify each image with a UUID in a URL.
 * The UUID is the critical part of a URL - all manner of image transformations
 * and reformatting can be applied as URL parameters after the URL
 *
 * All uploadcare image URLs follow the same foermat
 * https://ucarecdn.com/1e12b183-b988-4124-bbb6-3a4fe8c9a0c0/:parameters
 *
 * Where parameters are slash-delimited image transforms, filters, or effects
 *
 * Extracts the UUID from the URL to allow for creating new image URLs on demand
 *
 * @param {String} uploadcareUrl
 */
function getUuidFromUrl(uploadcareUrl) {
    if (!uploadcareUrl) return "";
    try {
        let u = new URL(uploadcareUrl);
        let path = u.pathname;
        let uuid = path.split("/")[1] || "";
        return uuid;
    } catch (err) {
        return "";
    }
}

/**
 * UploadCare specifies cropping with a URL parameter in the following format
 * /-/crop/537x214/0,0/
 *
 * /-/crop/ is the crop command
 * /537x214/ is the dimensions WxH
 * /0,0/ is the crop origin L x T
 *
 * This method extracts the cropping parameter from a URL
 * so that it can be reused
 * @param {String} uploadcareUrl
 */
function getCropParamfromUrl(uploadcareUrl) {
    if (!uploadcareUrl) return "";
    try {
        let u = new URL(uploadcareUrl);
        let matches = u.pathname.match(/\/-\/crop\/\d+x\d+\/\d+,\d+\//);
        return !!matches ? matches[0] : "";
    } catch (err) {
        return "";
    }
}

function createDbaLogoShadow(uuid, crop = "") {
    return `https://ucarecdn.com/${uuid}${crop}/-/grayscale/-/brightness/-100/-/blur/20/-/preview/300x160/`;
}

function createDbaLogo(uuid, crop = "") {
    return `https://ucarecdn.com/${uuid}${crop}/-/preview/300x160/`;
}

function createWhiteDbaLogo(uuid, crop = "") {
    return `https://ucarecdn.com/${uuid}${crop}/-/grayscale/-/brightness/100/-/preview/300x160/`;
}

/**
 * Return an UploadCare URL for the given UUID at the given size
 *
 * @param {String} uuid
 * @param {Number} width
 * @param {Number} height
 */
function resizeTo(uuid, width = 1920, height = 1080) {
    return `https://ucarecdn.com/${uuid}/-/preview/${width}x${height}/`;
}

/**
 * Scall an image down and crop any pixels which fall outside the dimensions
 *
 * @param {String} uuid
 * @param {Number} width
 * @param {Number} height
 */
function scaleCropTo(uuid, width = 1920, height = 1080, type = "center") {
    return `https://ucarecdn.com/${uuid}/-/scale_crop/${width}x${height}/${type}/`;
}

module.exports = {
    getUuidFromUrl,
    getCropParamfromUrl,
    createDbaLogoShadow,
    createDbaLogo,
    createWhiteDbaLogo,
    resizeTo,
    scaleCropTo,
};
